:root {
  --main-bg-color: #f4f8fb;
}

.toolbar-inner {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.07);
}

.tabbar::after {
  display: none;
}

.add-policy-nav-bar-shadow .navbar-bg::before,
.add-policy-nav-bar-shadow .navbar-bg::after,
.add-policy-progress-demo .navbar-bg::before,
.add-policy-progress-demo .navbar-bg::after {
  display: none;
}

.tabbar::after {
  display: none;
}

.add-policy-nav-bar-shadow .navbar-bg::before,
.add-policy-nav-bar-shadow .navbar-bg::after,
.add-policy-progress-demo .navbar-bg::before,
.add-policy-progress-demo .navbar-bg::after {
  display: none;
}

.tabbar .tab-link {
  padding      : 0 20px !important;
  border-bottom: 2px solid #FFFFFF;
}

.tabbar .tab-link-active {
  font-weight  : bold;
  border-bottom: 2px solid #FDA937;
}


.appBarShadow {
  background: white !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07) !important;
}


.customBackground {
  background: #f4f8fb !important;
}

.step {

  /* font-family: "OpenSans-Bold"; */
  font-weight: bold;
  font-size  : 12px;
  line-height: 23px;

  /* identical to box height, or 192% */
  text-align    : center;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  color  : #929ba2;
  display: none;
}

.pageTitle {

  /* font-family: "OpenSans-Bold"; */
  font-weight: bold;
  font-size  : 20px;
  line-height: 23px;

  /* identical to box height, or 115% */
  text-align: center;

  /* #07182B */
  color: #07182b;
}

.tool-tip-info {
  width       : 24px;
  height      : 24px;
  padding-left: 5px;
}

.btn-red {

  justify-content: center !important;
  align-items    : center !important;
  margin         : auto;
  display        : flex !important;
  padding        : 10px 120px 10px 120px !important;

  width : 298px !important;
  height: 43px !important;

  /* #FF3333 */
  background   : #ff3333 !important;
  border-radius: 34px !important;
  box-shadow   : none !important;
}

.btn-red-text {
  position      : static;
  text-transform: none !important;

  /* font-family: "OpenSans-Bold"; */
  font-weight: bold;
  font-size  : 15px;
  line-height: 23px;

  /* identical to box height, or 153% */
  text-align: center;

  /* #FFFFFF */
  color: #ffffff;

  letter-spacing: 0.04em;

  /* Inside Auto Layout */
  flex     : none;
  order    : 1;
  flex-grow: 0;
  margin   : 0px 10px;
}



.btn-white {
  position: relative;
  top     : 50px;

  margin : auto;
  display: flex;

  flex-direction : row !important;
  justify-content: center !important;
  align-items    : center !important;
  padding        : 10px 120px !important;
  width          : 298px !important;
  height         : 43px !important;

  /* #FF3333 */
  background   : #ffffff !important;
  border-radius: 34px !important;
  box-shadow   : none !important;
  border       : 1px solid #e1e7eb;
}

.btn-white-text {
  position      : static;
  left          : 38.59%;
  right         : 28.52%;
  top           : 23.26%;
  bottom        : 23.26%;
  text-transform: none !important;

  /* font-family: "OpenSans-SemiBold"; */
  font-weight: 600;
  font-size  : 15px;
  line-height: 23px;

  /* identical to box height, or 153% */
  text-align: center;

  /* #FFFFFF */
  color: #07182b;

  /* Inside Auto Layout */
  flex     : none;
  order    : 1;
  flex-grow: 0;
  margin   : 0px 10px;
}


.disclaimer {
  position: relative;

  margin : auto;
  display: flex !important;

  /* font-family: "OpenSans-SemiBold"; */
  font-weight: 600;
  font-size  : 11px;

  width: 310px !important;

  /* or 150% */
  text-align: center;

  /* #07182B */
  color: #07182b;

  opacity: 0.22;
}


/* MODAL STYLE  */

.btn-red-modal {

  display: flex !important;
  margin : auto;

  flex-direction : row !important;
  justify-content: center !important;
  align-items    : center !important;
  padding        : 10px 120px !important;

  position: center !important;
  width   : 203px !important;
  height  : 43px !important;

  /* #FF3333 */
  background   : #ff3333 !important;
  border-radius: 34px !important;
  box-shadow   : none !important;
}

.btn-red-text-modal {
  position      : static;
  left          : 38.59%;
  right         : 28.52%;
  top           : 23.26%;
  bottom        : 23.26%;
  text-transform: none !important;

  /* font-family: "OpenSans-Bold"; */
  font-weight: bold;
  font-size  : 15px;
  line-height: 23px;

  /* identical to box height, or 153% */
  text-align    : center;
  letter-spacing: 0.04em;

  /* #FFFFFF */
  color: #ffffff;

  /* Inside Auto Layout */
  flex     : none;
  order    : 1;
  flex-grow: 0;
  margin   : 0px 10px;
}

.modal-header {
  /* font-family: "OpenSans-Bold"; */
  font-weight: bold;
  font-size  : 20px;

  /* identical to box height, or 130% */
  text-align: center;

  /* #07182B */
  color: #07182b;
}

/* Main  */

.no-shadow .navbar-bg:before,
.no-shadow .navbar-bg:after,
.upload-nav .navbar-bg:before,
.upload-nav .navbar-bg:after {
  content: none !important;
}

.upload-nav .navbar-bg {
  background: #606d77 !important;
}

.Insurers-list {
  background-color: #FFFFFF;
  position        : relative;
  display         : flex;
  flex-direction  : column;
}

.Insurers-list::before {
  content          : '';
  background-image : url("../assets/family_bg.svg");
  background-repeat: no-repeat;
  position         : absolute;
  width            : 75px;
  height           : 130px;
  right            : 0px;
  top              : -30px;
}

.Insurers-list.no_bg::before {
  content: none;
}

.Insurers-list-wrapper {
  z-index: 10;
}

.Insurers-list h3 {
  color      : #07182B;
  font-weight: bold;
  width      : 100%;
  text-align : center;
  font-size  : 21px;
}

.familyMember-wrapper {
  display       : flex;
  padding       : 0 12px;
  overflow-x    : auto;
  padding-bottom: 15px;
  padding-top   : 15px;
  padding-left  : 24px;
}

.familyMember-wrapper:after {
  content       : "";
  display       : flex;
  flex-direction: column;
  align-items   : center;
  margin-right  : 5px;
  min-width     : 25px;
}

.familyMember {
  display       : flex;
  flex-direction: column;
  align-items   : center;
  padding-right : 0px;
  padding-left  : 0px;
  margin-right  : 8px;
  margin-left   : 0px;

}

.addFamiliyMemberBox {
  display          : flex;
  flex-direction   : column;
  align-items      : center;
  margin-right     : 12px;
  /* margin-left   : 12px; */

}

.addFamiliyMemberBox span {
  color         : #B7B8B7;
  font-size     : 14px;
  /* font-weight: 600; */
  width         : 70px;
  overflow      : hidden;
  text-overflow : clip;
  white-space   : nowrap;
  margin-top    : 7px;
  padding       : 2px 2px;

}

.familyMember img {
  width        : 44px;
  height       : 44px;
  border-radius: 50%;
  border       : 2px solid #E1E7EB;
}

.familyMember.active img {
  border: 2px solid #5E6D78;
}

.familyMember span {
  color        : rgba(7, 24, 43, 0.6);
  font-size    : 14px;
  font-weight  : 600;
  width        : 50px;
  overflow     : hidden;
  text-overflow: clip;
  white-space  : nowrap;
  margin-top   : 7px;
  padding      : 2px 10px;
}

.familyMember span.status::before {
  content     : "\25C6";
  margin-right: 3px;
  color       : #52B485;
  font-size   : 10px;
  line-height : 1px;
}

.familyMember span.status.alert::before {
  color: #FF3333;
}

.familyMember span.status.warn::before {
  color: #FDA937;
}

.familyMember span.status.pass::before {
  color: #11BBA2;
}

.familyMember.active span.status {
  border-radius   : 30px;
  background-color: #5E6D78;
  color           : #FFFFFF;
  font-weight     : 700;
  display         : flex;
  align-items     : center;
  overflow        : hidden;
  text-overflow   : ellipsis;

}



.familyMember-wrapper #addFamiliyMember {
  min-width      : 48px;
  height         : 48px;
  display        : flex;
  justify-content: center;
  align-items    : center;
  border-radius  : 50%;
  border         : 1px solid #E1E7EB;
}

.familyMember-wrapper #addFamiliyMember .icon {
  color      : #E1E7EB;
  font-size  : 12px;
  font-weight: bolder;
}


.footer-coverage {
  display          : flex;
  flex-direction   : column;
  align-items      : center;
  /* padding       : 0 20px; */
  padding-bottom   : 30px;
}

.footer-coverage h3 {
  color      : #1A2531;
  padding    : 0;
  margin     : 0px;
  font-style : normal;
  font-weight: bold;
  font-size  : 17px;
  line-height: 23px;
  text-align : center;
}

.footer-coverage span {
  font-weight: 600;
  font-size  : 14px;
  line-height: 19px;
  text-align : center;
  color      : #07182B;
  opacity    : 0.5;
  margin     : 12px 0px;
}

.footer-coverage .button {
  margin          : 0px 8px;
  padding         : 6px 16px;
  border-radius   : 34px;
  background-color: #5E6D78;
  color           : #FFFF;
  font-size       : 12px;
  text-transform  : uppercase;
  font-weight     : bolder;
  line-height     : 19px;
}

.proctection-tabs {
  display        : flex;
  justify-content: center;
  border-top     : 1px solid #E1E7EB;
  font-weight    : 600;
  padding-top    : 10px;
  padding-bottom : 11px;
  box-shadow     : 0px 4px 4px 0 rgba(0, 0, 0, 0.07);
}

.proctection-tabs .link {
  margin          : 0px 8px;
  padding         : 6px 20px;
  border-radius   : 60px;
  background-color: #F4F8FB;
  color           : rgba(7, 24, 43, .5);
  font-size       : 14px;
  line-height     : 23px;

}

.proctection-tabs .link.tab-link-active {
  background-color: #5E6D78;
  font-weight     : 700;
  color           : #FFFFFF;
}

.coverage-title {
  color         : #929BA1;
  font-size     : 12px;
  font-weight   : bold;
  text-transform: uppercase;
  margin-top    : 21px;
  margin-bottom : 0px;
  margin-left   : 20px;
}


.coverage-options {
  margin-top   : 16px;
  margin-bottom: 0px;
}

.coverage-options ul {
  background-color: var(--main-bg-color);
}

.coverage-options ul>li {
  border-radius   : 12px;
  margin-bottom   : 8px;
  box-shadow      : 0px 4px 4px rgba(0, 0, 0, 0.07);
  background-color: #FFFFFF;
}



.coverage-options ul>li.inactive {
  border-radius   : 12px;
  margin-bottom   : 8px;
  box-shadow      : 0px 4px 4px rgba(0, 0, 0, 0.07);
  background-color: #F4F8FB;
  border          : 1px dashed #C0CBD2;
}

.coverage-options ul>li .item-content {
  padding-left  : 25px;
  padding-top   : 19px;
  padding-bottom: 19px;

}

.coverage-options ul>li .item-media,
.coverage-options ul>li .item-inner {
  padding-top   : 0px;
  padding-bottom: 0px;

}

.coverage-options ul>li .item-inner {
  margin-left: 20px;

}

.coverage-options ul>li .item-inner::after {
  content: unset;

}

.coverage-options ul>li .item-title {
  color      : #07182B;
  font-size  : 17px;
  font-weight: bold;
}

.coverage-options ul>li .item-subtitle {
  color      : #07182B;
  font-size  : 14px;
  font-weight: 600;
}


.coverage-options ul>li.pass .item-subtitle {
  color: #11BBA2;
}

.coverage-options ul>li.warn .item-subtitle {
  color: #FDA937;
}

.coverage-options ul>li.alert .item-subtitle {
  color: #FF3333;
}

.coverage-options.list ul:before {
  content: unset;
}

.coverage-options .assistantAvatar {
  position    : relative;
  display     : flex;
  margin-right: 10px;
}

.coverage-options .assistantAvatar img {
  object-fit      : contain;
  object-position : 10px 5px;
  width           : 53.68px;
  height          : 53.68px;
  border-radius   : 50%;
  background-color: #ECF3F8;
}


.assistant .coverage-options ul>li .item-subtitle {
  font-style : normal;
  font-weight: 600;
  font-size  : 14px;
  line-height: 19px;
  color      : #07182B;
  opacity    : 0.5;
}

.coverage-options .assistantAvatar::after {
  content      : '';
  border-radius: 50%;
  width        : 12.39px;
  height       : 12.39px;
  top          : -2px;
  right        : -8px;
  background   : #C0CBD2;
  position     : absolute;
}

.coverage-options .assistantAvatar.online::after {
  background: #52B485;
}

.assistant .coverage-options ul>li.media-item .item-link .item-title-row:before {
  color: #3677C4;
}

.coverage-link {
  display  : flex;
  max-width: 48px;
  height   : 48px;
  position : relative;
}

.coverage-link img {
  width              : 26px;
  height             : 26px;
  position           : absolute;
  top                : 11px;
  left               : 11px;
  background-position: 50% 50%;
  background-repeat  : no-repeat;
}

.coverage-options ul>li.media-item .item-link .item-title-row:before {
  margin-top: 0px;
}

.coverage-banner {
  display               : flex;
  flex-direction        : column;
  border-radius         : 12px;
  background-color      : #FFFFFF;
  margin                : 26px 20px 20px 20px;
  padding               : 26px;
  height                : 230px;
  /* background-image   : url("../assets/portfolio_banner_alert.svg"); */
  background-repeat     : no-repeat;
  background-position   : 100% 0%;
  position              : relative;
}

.coverage-banner h3 {
  color        : #07182B;
  font-size    : 20px;
  font-weight  : bold;
  line-height  : 23px;
  max-width    : 170px;
  margin-bottom: 15px;
}

.coverage-banner span {
  font-weight  : 600;
  font-size    : 14px;
  line-height  : 23px;
  color        : #07182B;
  opacity      : 0.5;
  max-width    : 200px;
  margin-bottom: 14px;
}


.coverage-banner a {
  background      : #5E6D78;
  border-radius   : 34px;
  font-weight     : bold;
  font-size       : 14px;
  line-height     : 23px;
  text-align      : center;
  color           : #FFFFFF;
  /* width        : 126px; */
  padding         : 6px 16px;
  text-align      : center;
  position        : absolute;
  bottom          : 36px;
  z-index         : 999;
}

.coverage-banner.banner-alert a,
.coverage-banner.banner-warn a {
  background: #FF3333;
  color     : #FFFFFF;
  width     : 179px;
  padding   : 6px 16px;
}

.coverage-banner.banner-alert a::before,
.coverage-banner.banner-warn a::before {
  content         : '';
  background-image: url("../assets/sparks_white.svg");
  width           : 17.69px;
  height          : 20.21px;
  display         : flex;
  margin-right    : 10px;
}

.coverage-banner::before {
  content                   : '';
  position                  : absolute;
  display                   : block;
  background-repeat         : no-repeat;
  right                     : 0px;
  bottom                    : 0px;
  border-bottom-right-radius: 12px;
  background-size           : cover;
}

.coverage-banner::after {
  content                : '';
  position               : absolute;
  display                : block;
  background-repeat      : no-repeat;
  right                  : 0px;
  top                    : 0px;
  border-top-right-radius: 12px;
}

.coverage-banner.banner-alert::before,
.coverage-banner.banner-alert-s::before {
  background-image: url("../assets/coverage-alert.svg");
  width           : 110px;
  height          : 130px;
}

.coverage-banner.banner-alert::after,
.coverage-banner.banner-alert-s::after {
  background-image: url("../assets/coverage-alert-bg.svg");
  width           : 51.82px;
  height          : 63.28px;
}

.coverage-banner.banner-warn::before {
  background-image: url("../assets/coverage-warn.svg");
  width           : 205px;
  height          : 192px;
  z-index         : 99;
  background-size : auto;
}

.coverage-banner.banner-warn::after {
  background-image: url("../assets/coverage-warn-bg.svg");
  width           : 141px;
  height          : 131.28px;
}

.coverage-banner.banner-pass::before {
  background-image: url("../assets/coverage-pass.svg");
  width           : 177px;
  height          : 170px;
  z-index         : 99;
  background-size : auto;
}

.coverage-banner.banner-pass::after {
  background-image: url("../assets/coverage-pass-bg.svg");
  width           : 71.28px;
  height          : 73px;
}

.coverage-banner.update-policy {
  height : 155px;
  padding: 21px 26px;
}

.coverage-banner.update-policy::before {
  background-image: url("../assets/upload-doc.svg");
  width           : 113px;
  height          : 108px;
  z-index         : 99;
  bottom          : 16px;
  right           : 23px;
}

.coverage-banner.update-policy::after {
  background-image: url("../assets/upload-doc-bg.svg");
  width           : 86px;
  height          : 103px;
}

.dbs-button-base {
  font-style      : normal;
  font-weight     : bold;
  font-size       : 14px;
  line-height     : 23px;
  text-align      : center;
  color           : #FFFFFF;
  margin          : 0px;
  margin-right    : 8px;
  padding         : 6px 20px;
  border-radius   : 60px;
  background-color: #5E6D78;
  border          : 1px solid #5E6D78;
}

.dbs-button-base.alert {
  background-color: #FF3333;
  border          : 1px solid #FF3333;
}

.dbs-button-base.share {
  background   : #FFFFFF;
  color        : rgba(7, 24, 43, .5);
  border       : 1px solid #E1E7EB;
  max-width    : 91px;
  align-items  : center;
  display      : flex;
  align-items  : center;
  padding-left : 16px;
  padding-right: 13px;
}

.dbs-button-base.share::before {
  content          : '';
  background-image : url("../assets/customIcons/custom-share.svg");
  width            : 13px;
  height           : 16.61px;
  display          : block;
  margin-right     : 7px;
  background-repeat: no-repeat;
  background-size  : cover;
}


.coverage-banner.update-policy a {
  bottom     : 28px;
  font-style : normal;
  font-weight: bold;
  font-size  : 14px;
  line-height: 23px;
  text-align : center;
  color      : #FFFFFF;
  background : #FF3333;
}

.analysis-banner-wrapper {
  display       : flex;
  padding       : 0 12px;
  overflow-x    : auto;
  padding-bottom: 15px;
  padding-left  : 18px;
  padding-top   : 27px;
}

.analysis-banner-wrapper:after {
  content       : "";
  display       : flex;
  flex-direction: column;
  align-items   : center;
  min-width     : 18px;
}

.analysis-banner-wrapper> :last-child {
  margin-right: 0px;
}

.analysis-banner {
  display          : flex;
  flex-direction   : column;
  align-items      : center;
  /* width         : 296px; */
  min-width        : 70vw;
  height           : 368px;
  margin           : 0px;
  margin-right     : 26px;
  background       : #FFFFFF;
  box-shadow       : 0px 4px 4px rgba(0, 0, 0, 0.07);
  border-radius    : 12px;
  padding          : 18px 23px;
  position         : relative;
  overflow         : hidden;
}

.analysis-banner::before {
  content: '';
  width  : 126px;
  height : 126px;

  position     : absolute;
  top          : -34px;
  left         : -34px;
  border-radius: 50%;
  z-index      : 0;
}

.analysis-banner.pass::before {
  background: linear-gradient(212.77deg, #C4EDE7 37.89%, rgba(240, 244, 244, 0.04) 102.08%);
}

.analysis-banner.pass .banner-title::before {
  background-color: #11BBA2;
}

.analysis-banner.warn::before {
  background: linear-gradient(212.77deg, #FFF5E7 37.89%, rgba(255, 251, 252, 0.44) 102.08%);
}

.analysis-banner.warn .banner-title::before {
  background-color: #FDA937;
}

.analysis-banner.alert::before {
  background: linear-gradient(212.77deg, #FFE5EB 37.89%, rgba(255, 251, 252, 0.44) 102.08%);
}

.analysis-banner.alert .banner-title::before {
  background-color: #FF3333;
}


.analysis-banner * {
  z-index: 999;
}

.analysis-banner .banner-title-wrapper {
  display        : flex;
  width          : 100%;
  justify-content: left;
}

.analysis-banner .banner-title {
  font-style   : normal;
  font-weight  : bold;
  font-size    : 17px;
  line-height  : 23px;
  color        : #07182B;
  margin-bottom: 21px;
  display      : flex;
  align-items  : center;
}

.analysis-banner .banner-title::before {
  content         : '';
  border-radius   : 50%;
  width           : 8px;
  height          : 8px;
  display         : flex;
  background-color: black;
  margin-right    : 14px;
}

.analysis-banner .banner-logo {
  width        : 45px;
  height       : 48px;
  margin-bottom: 12px;
}

.analysis-banner .banner-policy {
  font-style   : normal;
  font-weight  : bold;
  font-size    : 20px;
  line-height  : 23px;
  text-align   : center;
  color        : #07182B;
  margin-bottom: 9px;
}

.analysis-banner .banner-amount {
  font-style   : normal;
  font-weight  : normal;
  font-size    : 14px;
  line-height  : 19px;
  text-align   : right;
  color        : #C0CBD2;
  display      : flex;
  margin-bottom: 10px;
}

.analysis-banner .banner-amount .covered {
  font-style : normal;
  font-weight: 600;
  font-size  : 14px;
  line-height: 19px;
  text-align : right;
}

.analysis-banner.pass .banner-amount .covered {
  color: #11BBA2;
}

.analysis-banner.warn .banner-amount .covered {
  color: #FDA937;
}

.analysis-banner.alert .banner-amount .covered {
  color: #FF3333;
}

.analysis-banner .banner-amount .separator {
  opacity: 0.5;
  margin : 0px 5px;
}

.analysis-banner .banner-amount .limit {
  color  : #07182B;
  opacity: 0.5;
}

.analysis-banner .banner-desciption {
  font-style : normal;
  font-weight: 600;
  font-size  : 14px;
  line-height: 23px;
  color      : #07182B;
  opacity    : 0.5;
  max-width  : 249px;
  margin-top : 19px;
}

.analysis-banner .banner-button {
  font-style    : normal;
  font-weight   : bold;
  font-size     : 12px;
  line-height   : 19px;
  text-align    : center;
  text-transform: uppercase;
  color         : #FFFFFF;
  background    : #5E6D78;
  border-radius : 34px;
  padding       : 6px 16px;
  position      : absolute;
  bottom        : 21px;
}


.analysis-banner .banner-button.disabled {
  opacity: 0.7 !important;
}

.analysis-banner .banner-button.active {
  color     : #FFFFFF;
  background: #FF3333;
}


/* POLICY INFORMATION STYLE */

.policy-title {
  color            : #07182b;
  font-weight      : bold;
  /* text-transform: capitalize; */
  font-size        : 21px;
  text-align       : center;
}

.policy-subtitle {
  color         : #07182b;
  font-weight   : bold;
  text-transform: none;
  font-size     : 14px;
  opacity       : 0.5;
}

.policy-amount {
  color      : #07182b;
  font-size  : 17px;
  font-weight: bold;
  text-align : end;
}

.policy-amount-peryear {
  color         : #07182b;
  font-size     : 12px;
  text-transform: none;
  opacity       : 0.5;
  text-align    : left;
  margin-left   : 5px;
}


.break-line {
  height      : 1px;
  background  : #e1e7eb;
  margin-left : 10px;
  margin-right: 10px;
}

.pending-btn {
  color       : #fda937;
  border-color: #fda937;
  border      : 1px solid #fda937;

  font-size  : 10px;
  font-weight: bold;

  width : 68px;
  height: 19px;
}

.active-btn {
  color       : #09bcc3;
  border-color: #09bcc3;
  border      : 1px solid #09bcc3;
  font-size   : 10px;
  font-weight : bold;

  width : 56px;
  height: 19px;
}

.policy-block-primary-text {
  color         : #8e8e93;
  font-size     : 14px;
  text-transform: none;
}

.policy-block-secondary-text {
  color     : #000000;
  font-size : 16px;
  text-align: end !important;

}

.policy-block-left-column {
  text-align: right;
}

.policy-detail-tab .item-inner::after {
  content: none;
}

.more-info-tab .item-inner::after,
.coverage-sum-detail-tab .item-inner::after,
.policy-owner-tab .item-inner::after {
  content: none;
}


.more-info-tab .item-inner,
.coverage-sum-detail-tab .item-inner,
.policy-owner-tab .item-inner {
  border-bottom: 1px solid #E1E7EB;
  padding-right: 0px;
}

.policy-owner-tab .item-inner {
  border-bottom: none;
}

.more-info-tab .item-content,
.coverage-sum-detail-tab .item-content,
.policy-owner-tab .item-content {
  padding-right: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-right) - var(--menu-list-offset));
}


.policy-block-secondary-text>.col {
  border-bottom : 1px solid #E1E7EB;
  padding-bottom: 15px;

}

.policy-detail-tab>ul> :last-child .policy-block-secondary-text>.col {
  border-bottom: 1px solid #FFFFFF;
}

.policy-detail-overlay-title {
  color      : #000000;
  font-size  : 16px;
  font-weight: bold;
  line-height: 22px;
}

.policy-detail-overlay-description {
  color      : #000000;
  font-size  : 16px;
  line-height: 22px;
  width      : 70%;
}

.policy-detail-overlay-tab {
  padding: 10px;
}

.policy-detail-overlay-tab>ul {
  background-color: rgba(0, 0, 0, 0.1);
  ;
}

.container-custom {
  width   : 300px;
  height  : 200px;
  position: relative;
  margin  : 0px;
}

.container-box {
  width     : 70%;
  height    : 70%;
  position  : absolute;
  opacity   : 0.8;
  background: red;
}

.container-box-overlay {
  z-index   : 2;
  margin    : 5px;
  background: green;
}

.policy-detail-tab,
.policy-owner-tab,
.coverage-sum-detail-tab,
.more-info-tab,
.nav-bar-shadow {
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.07);
  position  : relative;
}

.more-info-bocks>.item-content>.item-inner,
.coverage-sum-blocks>.item-content>.item-inner {
  padding-top   : 20px;
  padding-bottom: 20px;

}

.coverage-sum-blocks-second>.item-content>.item-inner {
  padding-top   : 11.6px;
  padding-bottom: 11.6px;

}

.coverage-sum-blocks-last>.item-content>.item-inner {
  padding-top   : 21.4px;
  padding-bottom: 21.4px;

}


.close-modal {
  font-size: 25px;
  color    : #9eaab1;
  width    : 30px;
  height   : 30px;

  position: fixed;
  left    : 8px;
  top     : 8px;
}

.policy_information_uploaded_file {
  display       : grid;
  place-items   : center;
  /* margin-top : 25%; */
  height        : 100%;
}

/* ADD POLICY STYLE */

.add-policy-navbar-title {
  text-align: center;
}

.add-policy-title {
  font-size    : 20px;
  line-height  : 23px;
  text-align   : center;
  font-weight  : bold;
  color        : #07182b;
  margin-bottom: 0px;
}

.add-policy-description {
  justify-content: center;
  align-items    : center;
  margin         : auto;

  line-height: 24px;
  text-align : center;
  color      : #07182b;
  opacity    : 0.5;
  font-weight: 600;

  padding-top                  : 25px;
  padding-bottom               : 25px;
  --f7-block-padding-horizontal: 15vw;
}

.add-policy-btn-red {
  justify-content: center;
  align-items    : center;
  margin         : auto;
  padding        : 10px 120px 10px 120px;
  width          : 298px;
  height         : 43px;
  background     : #ff3333;
  border-radius  : 34px;
  box-shadow     : none;
}

/* .need-analysis-btn-gray {
  justify-content: center;
  align-items    : center;
  margin         : auto;
  padding        : 10px 120px 10px 120px;
  width          : 298px;
  height         : 43px;
  background     : #5E6D78;
  border-radius  : 34px;
  box-shadow     : none;
  opacity        : 1;
} */

.add-policy-btn-red-text {
  position      : static;
  text-transform: none;
  font-size     : 15px;
  line-height   : 23px;
  text-align    : center;
  color         : #ffffff;
  letter-spacing: 0.02em;
  margin        : 0px 10px;
}

.add-policy-step {
  font-weight   : bold;
  font-size     : 12px;
  line-height   : 23px;
  text-align    : center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color         : #929BA2;
}


.add-policy-btn-white {
  justify-content: center;
  align-items    : center;
  margin         : auto;
  width          : 298px;
  height         : 43px;
  background     : #ffffff;
  border-radius  : 34px;
  box-shadow     : none;
  border         : 1px solid #e1e7eb;
}

.add-policy-btn-white-text {
  text-transform: none;
  font-size     : 15px;
  line-height   : 23px;
  text-align    : center;
  color         : #07182b;
  letter-spacing: 0.02em;
  margin        : 0px 10px;
  text-transform: capitalize;
}

.btn-transparent-red {
  justify-content: center;
  align-items    : center;
  margin         : auto;
  width          : 298px;
  height         : 43px;
  background     : none;
  box-shadow     : none;
}

.btn-transparent-red-text {
  font-size     : 15px;
  line-height   : 23px;
  text-transform: none;
  text-align    : center;
  color         : #ff3333;
  letter-spacing: 0.04em;
}


.navbar .button.custom-logout {
  font-style    : normal;
  font-weight   : 600;
  font-size     : 17px;
  line-height   : 24px;
  text-align    : right;
  color         : #ff3333;
  text-transform: none;
}

.no-nav-bar-simulation {
  background : #606d77;
  height     : 54px;
  align-items: center;
  display    : flex;

}

.add-policy-progress-demo {
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.07);
}

/* Needs Analysis Screens */

.need-analysis-btn-gray {
  justify-content: center;
  align-items    : center;
  margin         : auto;
  padding        : 10px 120px 10px 120px;
  width          : 100%;
  height         : 43px;
  background     : #5E6D78;
  border-radius  : 34px;
  box-shadow     : none;
  opacity        : 1;
}

.disabled.need-analysis-btn-gray {
  opacity: 0.7 !important;
}

.need-analysis-btn-gray-text {
  position      : static;
  text-transform: none;
  font-size     : 15px;
  line-height   : 23px;
  text-align    : center;
  color         : #ffffff;
  letter-spacing: 0.02em;


}

.need-analysis-landing-text {
  font-size  : 16px;
  line-height: 24px;
  font-weight: 600;

  text-align: center;
  color     : #07182B;
  opacity   : 0.5;

  padding-left : 10%;
  padding-right: 10%;
}

.need-analysis-title {
  font-size    : 20px;
  line-height  : 25px;
  font-weight  : bold;
  text-align   : center;
  font-weight  : bold;
  color        : #07182b;
  margin-bottom: 0px;

  padding-left : 10%;
  padding-right: 10%;

}

.need-analysis-step {
  font-weight   : bold;
  font-size     : 12px;
  line-height   : 23px;
  text-align    : center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color         : #929BA2;
}

.need-analysis-saving-inset {
  margin-top: 0px;

}

.need-analysis-saving-habit-insets {
  font-size      : 16px;
  line-height    : 22px;
  display        : flex;
  align-items    : center;
  text-align     : right;
  justify-content: center;
  color          : #000000;
  opacity        : 0.5;
  padding-bottom : 0px;
}

.need-analysis-saving-habit-insets-modal {
  font-size      : 16px;
  line-height    : 22px;
  display        : flex;
  align-items    : center;
  text-align     : right;
  justify-content: center;
  color          : #000000;
  opacity        : 0.5;
  padding-bottom : 10px;
}

.need-analysis-currency-sign {
  font-size    : 13px;
  line-height  : 18px;
  align-items  : center;
  color        : #000000;
  opacity      : 0.5;
  padding-right: 5px;
}

.need-analysis-currency-value {
  font-size  : 16px;
  line-height: 22px;
  align-items: center;
  color      : #000000;
}

.need-analysis-btn-red {
  /* btns values */
  justify-content: center;
  align-items    : center;
  margin         : auto;
  padding        : 10px 120px 10px 120px;
  width          : 298px;
  height         : 43px;
  background     : #ff3333;
  border-radius  : 34px;
  box-shadow     : none;
  /* internal text values */
  font-weight    : bold;
  font-size      : 15px;
  line-height    : 23px;
  text-align     : center;
  color          : #FFFFFF;
  text-transform : none;
}

.need-analysis-btn-white {
  /* btns values */
  justify-content: center;
  align-items    : center;
  margin         : auto;
  padding        : 10px 120px 10px 120px;
  width          : 298px;
  height         : 43px;
  background     : #FFFFFF;
  ;
  border-radius : 34px;
  box-shadow    : none;
  /* internal text values */
  font-size     : 15px;
  line-height   : 23px;
  text-align    : center;
  color         : #07182B;
  text-transform: none;
}

.need-analysis-description {
  font-weight  : 600;
  font-size    : 11px;
  line-height  : 17px;
  text-align   : center;
  color        : #b7b8b7;
  margin-top   : 0px;
  margin-bottom: 0px;
}

.need-analysis-scroll-value {
  font-style : normal;
  font-weight: bold;
  font-size  : 40px;
  line-height: 54px;
  text-align : center;
  color      : #07182B;
  display    : block;
}

.need-analysis-inset-title {
  font-size  : 16px;
  line-height: 22px;
  display    : block;
  align-items: center;
  text-align : center;
  color      : #000000;
  opacity    : 0.5;
}

.need-analysis-retirement-terms {
  font-weight: 600;
  font-size  : 11px;
  line-height: 17px;
  text-align : center;
  color      : #b7b8b7;
  margin-top : 5px;
}

.need-analysis-retirement-fill-btn {
  /* btns values */
  display       : flex;
  flex-direction: row;
  align-items   : flex-start;
  padding       : 6px 16px;
  position      : static;
  width         : 83px;
  height        : 31px;
  left          : 0px;
  top           : 4px;
  background    : #5E6D78;
  border-radius : 34px;
  /* internal text values */
  font-weight   : bold;
  font-size     : 12px;
  line-height   : 19px;
  text-align    : center;
  text-transform: uppercase;
  color         : #FFFFFF;


  --f7-button-pressed-bg-color  : rgba(0, 0, 0, 0.15);
  --f7-button-pressed-text-color: #FFFFFF;

}

.need-analysis-retirement-fill-btn.disabed,
.need-analysis-retirement-no-fill-btn.disabed {
  /* btns values */
  background: #5E6D78;
  color     : #FFFFFF;
  opacity   : 0.5;

}

.need-analysis-retirement-no-fill-btn {
  /* btns values */
  display       : flex;
  flex-direction: row;
  align-items   : flex-start;
  padding       : 6px 16px;
  position      : static;
  width         : 85px;
  height        : 31px;
  left          : 170px;
  top           : 4px;
  background    : #FFFFFF;
  border        : 1px solid #5E6D78;
  box-sizing    : border-box;
  border-radius : 34px;
  /* internal text values */
  font-weight   : bold;
  font-size     : 12px;
  line-height   : 19px;
  text-align    : center;
  text-transform: uppercase;
  color         : #5E6D78;

  --f7-button-pressed-bg-color  : rgba(0, 0, 0, 0.15);
  --f7-button-pressed-text-color: #FFFFFF;

}

.saving-habits-tab {
  margin-bottom: 10px;
  margin-top   : 10px;
  box-shadow   : 0px 4px 4px 0 rgba(0, 0, 0, 0.07);
}

.saving-habits-tab-invalid * {
  background-color: red;
}

.saving-habits-tab-valid * {
  background-color: yellow;
}


.range-limits-min-max {
  font-weight   : bold;
  font-size     : 12px;
  line-height   : 23px;
  letter-spacing: 0.04em;
  color         : #929BA2;
  margin-bottom : 0px;
}

.toolbar-prev-step {
  font-size  : 15px;
  line-height: 22px;

  display    : flex;
  align-items: center;
  text-align : center;
  /* FIX THIS position */
  position   : relative;
  right      : 34%;

  color: #000000;
}

.toolbar-aligment .toolbar-inner {
  justify-content: center;
}

.go-back-link {
  position    : absolute;
  left        : 15px;
  padding-left: 15px;
}



.month-saving-input *,
.month-expenses-input *,
.month-income-input *,
.total-saving-input * {
  text-align  : left;
  padding-left: 10px;
}

.saving-input {
  width           : 100%;
  height          : var(--f7-input-height);
  color           : var(--f7-input-text-color);
  font-size       : var(--f7-input-font-size);
  background-color: var(--f7-input-bg-color, transparent);
  padding-left    : var(--f7-input-padding-left);
  padding-right   : var(--f7-input-padding-right);
  border          : none;
  text-align      : center !important;
}


.saving-input+.input-error-message {
  display: none;
}

.invalid-Box-Input .saving-input+.input-error-message {
  display        : flex;
  width          : 100%;
  justify-content: center;
  margin-top     : -4px;
}

.d-first-name *,
.d-last-name *,
.e-mail *,
.phone-number *,
.d-bday * {
  text-align: center;
}

.health-insurance-concern-list-active .item-inner::after,
.health-insurance-concern-list .item-inner::after {
  content      : none;
  padding-right: 0px !important;
}

.health-insurance-concern-list-active .item-inner,
.health-insurance-concern-list .item-inner {
  content      : none;
  padding-right: 0px !important;
}


.health-insurance-concern-list-active .item-content,
.health-insurance-concern-list .item-content {
  padding-left : 0px;
  padding-right: 0px !important;

}

.my-health-insurance-title .item-inner {
  justify-content: center;
}



/* hide the input error messasge */
.total-saving-input .input-error-message,
.month-income-input .input-error-message,
.month-expenses-input .input-error-message,
.month-saving-input .input-error-message,
.anual-expenses .input-error-message,
.month-retirement-expenses-input .input-error-message {
  display: none;
}

.list-item-with-error {
  background: red;
}

.box-dolar-sign {
  padding-top: 14px;
  font-size  : 13px;
  line-height: 18px;
  color      : #929ba2;
}

.analysis-gray-btn-text {
  font-weight: bold;
  font-size  : 15px;
  line-height: 23px;
  text-align : center;

  color         : #FFFFFF;
  text-transform: none;

}

.toolbar-inner {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.00);
}

.simple-list-dependent {
  font-size  : 16px;
  line-height: 36px;
  color      : #000000;

}

.scrollable-body-modal {
  font-size  : 16px;
  line-height: 52px;
  color      : #000000;
  /* TEMP CODE */
  overflow-y : scroll;
  height     : 90%;
}

.invalid-Box-Input {
  --f7-input-error-text-color: #ff3333;
  --f7-list-bg-color         : #ffeef2;
}

.valid-Box-Input {
  --f7-input-error-text-color: black;
  --f7-list-bg-color         : #ffffff;
}

.btns-block {
  justify-content: center;
  /* flex-wrap   : nowrap; */
}

.need-analysis-currency-value * {
  height: 100% !important
}

/* Add Insured */

.upload-insured-screen {
  font-size      : 17px;
  line-height    : 26px;
  display        : flex;
  align-items    : center;
  text-align     : right;
  justify-content: center;
  color          : #000000;
  padding-bottom : 20px;
}

.insurance-details-tab {
  margin-bottom: 15px;
  margin-top   : 15px;
  margin-right : 20px !important;
  margin-left  : 20px !important;
  box-shadow   : 0px 4px 4px 0 rgba(0, 0, 0, 0.07);
}

.add-insured-insets-modal {
  font-size      : 16px;
  line-height    : 22px;
  display        : flex;
  align-items    : center;
  text-align     : right;
  justify-content: center;
  color          : #000000;
  padding-bottom : 10px;
}


.analysis-banner-slider .range-knob {
  background-image   : url("../assets/thumbs-up.svg");
  background-repeat  : no-repeat;
  padding            : 5px 11px;
  background-color   : #ffffff;
  border-radius      : 0px;
  box-shadow         : none;
  width              : 18px;
  height             : 17.1px;
  display            : block;
  box-sizing         : unset;
  background-position: 50% 25%;
}

.analysis-banner-slider.range-slider.range-slider-horizontal {
  opacity: 1 !important;
}

.analysis-banner-slider.range-slider-horizontal.alert .range-knob {
  background-image: url("../assets/thumbs-down.svg");
}

.analysis-banner-slider.range-slider-horizontal.pass .range-bar-active {
  background: #11bba2;
}

.analysis-banner-slider.range-slider-horizontal.warn .range-bar-active {
  background: #fda937;
}

.analysis-banner-slider.range-slider-horizontal.alert .range-bar-active {
  background: #ff3333;
}

.dbs-card {
  box-shadow   : 0px 4px 4px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  display      : flex;

}

.saving-alert::before {
  content          : '';
  width            : 44px;
  height           : 44px;
  background-image : url("../assets/red-alert-icon.svg");
  margin-right     : 21px;
  background-repeat: no-repeat;
  background-size  : contain;
}

.setting-btn {
  width           : 158px;
  height          : 153px;
  background-color: #FFFFFF;
  box-shadow      : 0px 4px 4px rgba(0, 0, 0, 0.07);
  border-radius   : 12px;
  display         : block;

  /* inner text css */
  text-transform: none;
  font-weight   : bold;
  font-size     : 16px;
  color         : #07182B;
}

.setting-btn-reset {
  width        : 158px;
  height       : 153px;
  background   : #F4F8FB;
  box-shadow   : 0px 4px 4px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  border       : 1px solid #E1E7EB;
  display      : block;

  /* inner text css */
  text-transform: none;
  font-weight   : bold;
  font-size     : 16px;
  color         : #07182B;


}

.setting-icon {
  margin-top: 40px;
}

.setting-text {
  margin-top: 10px;
}

.setting-row {
  margin-bottom  : 20px;
  display        : flex;
  justify-content: space-evenly;
}

.setting-row.left {
  justify-content: flex-start;
  padding-left   : 20px;
}

/* Sign up phone verification style */
.page-suw {
  background    : #f4f8fb;
  color         : #07182b;
  display       : flex;
  flex-direction: column;
}

.page-suw .page-header {
  display        : flex;
  position       : relative;
  justify-content: space-between;
  padding        : 0px 17px 30px 22px;
  margin-top     : 29px;
}

.page-suw .page-header span {
  font-style : normal;
  font-weight: 600;
  font-size  : 26px;
  line-height: 35px;
  text-align : center;
  color      : #07182b;
  width      : 245px;
}

.page-suw .page-header .button.btn-close {
  /* position   : absolute;
  right      : 5px;
  padding-top: 5px; */
  /* position: absolute; */
  /* right: -10px; */
  padding       : 0;
  /* padding-top: 5px; */
}

.page-suw .page-header .btn-back {
  /* position   : absolute; */
  /* left       : 28px; */
  padding-top: 5px;
  height     : fit-content;
}

.page-suw .page-header .button .f7-icons {
  color      : rgb(158, 170, 177);
  /* #9eaab1; */
  font-size  : 28px;
  font-weight: 500;
}

.page-suw .page-subtitle {
  margin-top     : 6px;
  margin-bottom  : 31px;
  display        : flex;
  flex-direction : column;
  justify-content: center;
  padding        : 0 49px;
  align-items    : center
}

.page-suw .page-subtitle span {
  font-style : normal;
  font-weight: bold;
  font-size  : 20px;
  line-height: 26px;
  text-align : center;
  color      : #07182b;
}

.page-suw .page-subtitle small {
  font-style : normal;
  font-weight: normal;
  font-size  : 17px;
  line-height: 23px;
  text-align : center;
  color      : #07182B;
  width      : 260px;
  margin-top : 18px;
}

.phoneNumberInputWrapper {
  padding      : 19px 20px 23px 20px;
  background   : #ffffff;
  margin       : 0px 20px;
  box-shadow   : 0px 1px 0px rgba(0, 0, 0, 0.14);
  border-radius: 12px;
  display      : flex;
}

.phoneNumberInputWrapper::before {
  content      : "+65";
  display      : flex;
  padding-right: 10px;
  border-right : 1px solid #c4c4c4;
  font-style   : normal;
  font-weight  : normal;
  font-size    : 16px;
  line-height  : 22px;
  display      : flex;
  color        : #000000;
  margin-right : 10px;
}

.phoneNumberInputWrapper input {
  font-style : normal;
  font-weight: normal;
  font-size  : 16px;
  line-height: 22px;
  color      : #000000;
}

.phoneNumberInputWrapper .input {
  width: 100%;
}

.btn-wapper {
  position       : absolute;
  display        : flex;
  flex-direction : column;
  justify-content: center;
  align-items    : center;
  bottom         : 32px;
  width          : 100%;
}

.btn-wapper .link {
  font-style          : normal;
  font-weight         : normal;
  font-size           : 16px;
  line-height         : 22px;
  display             : flex;
  align-items         : center;
  text-align          : center;
  text-decoration-line: underline;
  color               : #000000;
  opacity             : 0.5;
  margin-bottom       : 32px;
}

.btn-wapper .link:hover {
  opacity: 1;
}

.btn-suw {
  width         : 275px;
  background    : #5e6d78;
  border-radius : 34px;
  color         : #ffffff;
  font-style    : normal;
  font-weight   : bold;
  font-size     : 15px;
  line-height   : 23px;
  text-align    : center;
  color         : #ffffff;
  padding       : 10px;
  height        : unset;
  text-transform: capitalize;
}

.btn-suw.disabled {
  opacity: 0.5 !important;
}

a.add-policy-btn {
  font-style    : normal;
  font-weight   : bold;
  font-size     : 14px;
  line-height   : 23px;
  text-align    : center;
  color         : #FFFFFF !important;
  background    : #FF3333;
  border-radius : 34px;
  text-transform: none;
}

.coverage-banner.update-policy a::before,
.dbs-button-base.alert.add::before,
a.add-policy-btn.icon::before {
  content          : '';
  width            : 12px;
  height           : 12px;
  background-image : url("../assets/plus-icon-w.svg");
  margin-right     : 5px;
  background-repeat: no-repeat;
  background-size  : contain;

}

a.add-policy-btn.gray {
  color           : #FFFFFF;
  background-color: #5e6d78;
  border-radius   : 34px;
  text-transform  : none;
  height          : 35px;
  padding         : 0 15px;
  margin-left: 50px;
  border          : 1px solid rgba(142, 142, 147, 0.5);
}

a.add-policy-btn.share::before {
  content          : '';
  width            : 17px;
  height           : 17px;
  background-image : url("../assets/customIcons/custom-share.svg");
  margin-right     : 5px;
  background-repeat: no-repeat;
  background-size  : contain;
  filter           : invert(1);
}

a.add-policy-btn.white {
  color         : #8e8e93 !important;
  background    : #FFFFFF;
  border-radius : 34px;
  text-transform: none;
  height        : 35px;
  border        : 1px solid rgba(142, 142, 147, 0.5);
}

a.add-policy-btn.white img {
  margin-left : 12px;
  margin-right: 7px;
  height      : 16px;
  object-fit  : contain;

}

.span-hint {
  display        : flex;
  justify-content: center;
}